import { defineStore } from "pinia";
import request from "../http";

function getLocalStorageInfos() {
  let infos = localStorage.getItem("auth_payload");
  return (infos = JSON.parse(infos));
}

export const usePabxUserStore = defineStore("usePabxUserStore", {
  state: () => {
    return {
      users: [],
    };
  },

  actions: {
    async getModules() {
      return request.get("/list_modules");
    },
    async getPabxUsers(pabxId) {
      return request.get(`/list_access/${pabxId}`);
    },
    async getUser(server, userId) {
      return request.get(`/server/${server}/web_user/${userId}`);
    },
    async createUser(server, userInfos) {
      return request.post(`/server/${server}/web_user`, {
        id_empresas: userInfos.id_empresas,
        nome: userInfos.nome,
        login: userInfos.login,
        senha: userInfos.senha,
        modulos_sip: userInfos.modulos_sip,
        modulos_dialplan: userInfos.modulos_dialplan,
        modulos_pabx: userInfos.modulos_pabx,
        modulos_gerenciamento: userInfos.modulos_gerenciamento,
        modulos_relatorios_persa: userInfos.modulos_relatorios_persa,
        modulos_callcenter: userInfos.modulos_callcenter,
      });
    },

    async getUserPermissions(server, userId) {
      return request.get(`server/${server}/web_user/${userId}`);
    },

    async editUser(server, userInfos) {
      const authInfos = getLocalStorageInfos();
      const USER_EMAIL = `${authInfos.username}`;

      return request.put(`/server/${server}/web_user/${userInfos.id}`, {
        id_empresas: userInfos.id_empresas,
        nome: userInfos.nome,
        login: userInfos.login,
        senha: userInfos.senha,
        modulos_sip: userInfos.modulos_sip,
        modulos_dialplan: userInfos.modulos_dialplan,
        modulos_pabx: userInfos.modulos_pabx,
        modulos_gerenciamento: userInfos.modulos_gerenciamento,
        modulos_relatorios_persa: userInfos.modulos_relatorios_persa,
        modulos_callcenter: userInfos.modulos_callcenter,
        user_email: USER_EMAIL
      });
    },
    async deleteUser(server, userId) {
      return request.delete(`/server/${server}/web_user/${userId}`);
    },
  },
});
