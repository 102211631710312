<template>
  <section
    class="sections__default relative"
  >
    <Header>
      <h1 class="header__title">{{ $t("virtual_pabx") }}</h1>
    </Header>
    <div class="w-full px-4">
      <div class="flex flex-col sm:flex-row gap-5 items-center mb-2">
        <CreateButton
          class="w-full sm:w-auto"
          @click="createPabxModal"
          :btnText="$t('add_virtual_pabx')"
        />
        <Searcher
          :searchName="pabxName"
          @search-action="searchPabx"
          :placeholderText="$t('search_pabx')"
        />
      </div>
      <div class="flex items-center gap-5 mt-5 max-w-screen overflow-x-auto">
        <button
          @click="tabToggle('favorites')"
          class="py-1.5 px-4 rounded-md text-center text-xs 2xl:text-sm font-bold text-zinc-500"
          :class="{
            'bg-slate-500/20 dark:bg-slate-400/20 shadow-sm shadow-slate-500/30 text-slate-600 dark:text-slate-300':
              tabActive === 'favorites',
          }"
        >
          <span>
            {{ $t("favorites") }}
          </span>
        </button>

        <button
          @click="tabToggle('active')"
          class="p-2 rounded-md text-center text-zinc-500 text-xs 2xl:text-sm font-bold"
          :class="{
            'bg-slate-500/20 dark:bg-slate-400/20 shadow-sm shadow-slate-500/30 text-slate-600 dark:text-slate-300':
              tabActive === 'active',
          }"
        >
          <span>
            {{ $t("the_active") }}
          </span>
        </button>

        <button
          @click="tabToggle('blocked')"
          class="p-2 rounded-md text-center text-zinc-500 text-xs 2xl:text-sm font-bold"
          :class="{
            'bg-slate-500/20 dark:bg-slate-400/20 shadow-sm shadow-slate-500/30 text-slate-600 dark:text-slate-300':
              tabActive === 'blocked',
          }"
        >
          <span>
            {{ $t("blocked") }}
          </span>
        </button>

        <button
          @click="tabToggle('disabled')"
          class="p-2 rounded-md text-center text-zinc-500 text-xs 2xl:text-sm font-bold"
          :class="{
            'bg-slate-500/20 dark:bg-slate-400/20 shadow-sm shadow-slate-500/30 text-slate-600 dark:text-slate-300':
              tabActive === 'disabled',
          }"
        >
          <span>
            {{ $t("disabled") }}
          </span>
        </button>
        <button
          @click="tabToggle('all')"
          class="p-2 rounded-md text-center text-zinc-500 text-xs 2xl:text-sm font-bold"
          :class="{
            'bg-slate-500/20 dark:bg-slate-400/20 shadow-sm shadow-slate-500/30 text-slate-600 dark:text-slate-300':
              tabActive === 'all',
          }"
        >
          <span>
            {{ $t("all") }}
          </span>
        </button>
      </div>
      <div
        class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-5 gap-5 pt-4 items-start"
      >
        <PabxCard
          v-for="(pabx, index) in pabxList"
          :key="index"
          :pabxInfos="pabx"
          @delete-pabx="deletePabxServer"
          @refresh-list="refreshPabxList"
          @handle-notification="handleNotification"
          @edit-pabx="editPabxServer(pabx.id_core_server, pabx.id)"
          @pabx-migration="handlePabxMigration"
        />

        <button
          @click="createPabxModal"
          class="min-h-[160px] h-full border-2 border-dashed border-zinc-400/50 w-full rounded-lg flex flex-col items-center justify-center gap-3 transition-all group hover:border-solid"
        >
          <ViewGridAddIcon class="w-8 h-8 text-zinc-400/80" />
          <p class="text-zinc-600/80 text-xs 2xl:text-sm font-bold">
            {{ $t("add_virtual_pabx") }}
          </p>
        </button>
      </div>
    </div>
  </section>

  <!-- modals -->
  <CreatePabx
    v-if="isOpen === 'create-pabx-server'"
    :select-sip-server="serverSipInfo"
    :select-plan="serverPlanInfo"
    @close-modal="closeModal"
    @handle-notification="handleNotification"
    @refresh-list="refreshPabxList"
  />

  <EditPabx
    v-if="isOpen === 'edit-pabx-server'"
    :pabx-infos="serverInfos"
    @close-modal="closeModal"
    @handle-notification="handleNotification"
    @refresh-list="refreshPabxList"
  />

  <CreateUser v-if="isOpen === 'create-user'" :closeModal="closeModal" />

  <DeletePabx
    v-if="isOpen === 'delete-pabx-modal'"
    :itemToBeDeleted="itemToDelete"
    @handle-delete-action="confirmDelete"
    @close-modal="closeModal"
  />

  <Teleport to="body">
    <Transition name="notification">
      <Notification
        v-if="notificationState.show"
        :msg="notificationState.msg"
        :status="notificationState.status"
      />
    </Transition>
  </Teleport>
</template>

<script>
import { Link } from "@inertiajs/inertia-vue3";
import CreateButton from "../../../components/buttons/TheCreator.vue";
import Header from "../../../components/header/TheHeader.vue";
import Searcher from "../../../components/inputs/TheSearcher.vue";
import CreatePabx from "./modal/CreatePabx.vue";
import EditPabx from "./modal/EditPabx.vue";
import FormPabx from "./modal/Form.vue";
import DeletePabx from "../../../components/modal/DeleteModal.vue";
import CreateUser from "./modal/CreateUser.vue";
import Notification from "../../../components/notification/Notification.vue";
import { usePabxStore } from "../../../../store/PabxStore.js";
import PabxCard from "./buttons/PabxCard.vue";
import { PlusIcon, ViewGridAddIcon } from "@heroicons/vue/outline";

export default {
  name: "Pabx",
  components: {
    Link,
    CreateButton,
    Header,
    Searcher,
    CreatePabx,
    EditPabx,
    FormPabx,
    DeletePabx,
    CreateUser,
    Notification,
    PabxCard,
    PlusIcon,
    ViewGridAddIcon,
  },
  data() {
    return {
      requestCounter: 0,
      pabxStore: usePabxStore(),
      // pabxList: [],
      serverInfos: [],
      serverId: [],
      isOpen: "",
      status: "",
      favorite: 1,
      infos: {
        favorite: true,
      },
      pabxName: "",
      notificationState: {
        status: "",
        msg: "",
        show: false,
      },
      tabActive: "favorites",
      itemToDelete: {},
    };
  },
  methods: {
    closeModal() {
      this.isOpen = "";
    },
    editPabxServer(serverId, pabxId) {
      this.pabxStore
        .getPabxInfosToEdit(serverId, pabxId)
        .then(({ data }) => {
          this.serverInfos = [];
          this.serverInfos = data.data;
          this.serverInfos.id = pabxId;
          this.isOpen = "edit-pabx-server";
        })
        .catch(() => {
          const notificationInfos = {
            msg: "Erro ao buscar dados",
            status: "error",
            show: true,
          };

          this.handleNotification(notificationInfos);
        });
    },
    deletePabxServer(pabx) {
      this.itemToDelete = pabx;
      return (this.isOpen = "delete-pabx-modal");
    },
    confirmDelete(pabx) {
      let serverId = pabx.id_core_server;
      let pabxId = pabx.id;

      this.pabxStore.deletePabx(serverId, pabxId).then(({ data }) => {
        const notificationInfos = {
          msg: data.message,
          status: "success",
          show: true,
        };

        this.handleNotification(notificationInfos);
        this.closeModal();

        if (this.tabActive === "favorites") {
          this.favorite = 1;
          this.refreshPabxList();
          return;
        }

        this.favorite = 0;
        this.refreshPabxList();
      });
    },
    createPabxModal() {
      this.pabxStore
        .getNewPabxInfos()
        .then((response) => {
          this.serverInfos = [];

          this.serverInfos = response;
          this.isOpen = "create-pabx-server";
        })
        .catch((error) => {
          const notificationInfos = {
            msg: error.response.data.message,
            status: "error",
            show: true,
          };

          this.handleNotification(notificationInfos);
        });
    },
    getServerId() {
      let response = this.pabxStore
        .getServerSip()
        .then(({ data }) => data)
        .catch((error) => console.log(error));

      return response;
    },
    handleNotification(notificationInfos) {
      const { msg, status, show } = notificationInfos;

      this.notificationState.msg = msg;
      this.notificationState.status = status;
      this.notificationState.show = show;

      setTimeout(() => {
        this.notificationState.msg = "";
        this.notificationState.status = "";
        this.notificationState.show = false;
      }, 2500);
    },
    refreshPabxList(textToFind = "") {
      const favorites = this.favorite;
      const status = this.status;
      const text = textToFind;

      const { central_id, url } = JSON.parse(localStorage.getItem("auth_payload"));

      this.pabxStore
        .getPabxList(favorites, status, text, central_id, url)
        .then(({ data }) => {
          this.pabxStore.pabxList = data.data;
        })
        .catch(() => {
          this.pabxStore.pabxList = [];
          this.refreshPabxList(text);
        });
    },

    tabToggle(tab) {
      this.favorite = 0;
      this.status = "";

      if (tab === "favorites") {
        this.tabActive = "favorites";
        this.favorite = 1;
        return this.refreshPabxList();
      }

      if (tab === "active") {
        this.tabActive = "active";
        this.status = 1;
        return this.refreshPabxList();
      }

      if (tab === "blocked") {
        this.tabActive = "blocked";
        this.status = 2;
        return this.refreshPabxList();
      }

      if (tab === "disabled") {
        this.tabActive = "disabled";
        this.status = 0;
        return this.refreshPabxList();
      }

      this.tabActive = "all";

      return this.refreshPabxList();
    },

    searchPabx(text) {
      this.refreshPabxList(text);
    },

    handlePabxMigration() {
      const notificationInfos = {
        msg: "PABX em migração! Tente novamente mais tarde",
        status: "error",
        show: true,
      };

      this.handleNotification(notificationInfos);
    },
  },
  computed: {
    pabxList() {
      return this.pabxStore.pabxList;
    },
    serverSipInfo() {
      return this.serverInfos[0]?.data?.data;
    },
    serverPlanInfo() {
      return this.serverInfos[1]?.data?.data;
    },
  },
  mounted() {
    this.refreshPabxList();
    /*this.serverId = this.getServerId();*/
  },
};
</script>
