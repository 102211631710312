<template>
  <section
    class="sections__default md:max-h-screen overflow-hidden flex flex-col md:flex-row relative"
  >
    <main
      class="w-full sm:max-h-full md:overflow-y-auto md:sidebar"
      :class="[
        aside
          ? '2xl:max-w-7xl border-r border-zinc-200 dark:border-zinc-800 '
          : 'w-full',
      ]"
    >
      <Header>
        <h1 class="header__title">
          {{ $t("virtual_pabx") }} - {{ $t("users") }}
        </h1>
        <Link v-if="!aside" :href="$route('pabx.pabx.all')">
          <span
            class="py-1 px-2 rounded-md border border-slate-300 text-slate-400 hover:bg-white dark:hover:bg-slate-800 hover:border-slate-400 hover:text-slate-500 text-xs sm:text-sm flex items-center gap-1"
          >
            <ArrowSmLeftIcon class="w-4 h-4" />
            {{ $t("back") }}
          </span>
        </Link>
      </Header>

      <div class="px-5">
        <CreateButton
          class="w-full sm:w-auto mb-5"
          @click="createUserModal"
          :btnText="$t('add_user')"
        />
        <UsersTable
          v-if="pabxUsers.length > 0"
          :pabx-users="pabxUsers"
          @edit-user="getUser"
          @delete-user="deleteUserModal"
        />
      </div>
      <div v-if="pabxUsers.length < 1" class="px-5">
        <h2 class="text-center text-sm sm:text-base 2xl:text-lg text-slate-400">
          {{ $t("none_user_found") }}
        </h2>
      </div>
    </main>

    <Transition v-if="aside" name="aside">
      <aside
        ref="editMenuAside"
        class="bg-white dark:bg-zinc-900 p-5 max-w-sm w-full overflow-x-hidden sidebar absolute right-0 z-50 max-h-screen"
      >
        <button
          @click="aside = false"
          class="flex justify-end w-full text-zinc-400 hover:text-zinc-500 dark:text-zinc-500 dark:hover:text-zinc-400"
        >
          <XIcon class="w-5 h-5" />
        </button>
        <div class="flex flex-col flex-1 max-h-full">
          <div class="flex flex-col gap-5 mb-10">
            <form
              id="editUserForm"
              class="hidden"
              @submit.prevent="editUserSetup"
              :validation-schema="userSchema"
              ref="userForm"
            ></form>
            <div class="w-full relative">
              <span
                class="text-xs font-semibold text-zinc-500 uppercase mb-2 block"
              >
                {{ $t("name") }}
              </span>
              <input
                type="text"
                form="editUserForm"
                class="text-xs 2xl:text-sm peer border-2 border-transparent py-1.5 rounded-lg text-zinc-500 dark:text-zinc-300 outline-none w-full bg-zinc-700/10 dark:bg-zinc-700/30 px-3 focus:bg-zinc-700/20 dark:focus:bg-zinc-700/70 focus:border-zinc-200 font-mono placeholder:text-zinc-500 placeholder:text-sm placeholder:font-medium"
                :class="[
                  nameError
                    ? ' focus:border-red-500'
                    : ' focus:border-emerald-500',
                ]"
                :placeholder="$t('insert_a_name')"
                v-model="name"
              />
              <span
                class="text-xs text-red-400 font-bold absolute top-0 right-0 lowercase"
              >
                {{ nameError }}
              </span>
            </div>
            <div class="w-full relative">
              <span
                class="text-xs font-semibold text-zinc-500 uppercase mb-2 block"
                >{{ $t("email") }}</span
              >
              <input
                type="email"
                form="editUserForm"
                class="text-xs 2xl:text-sm peer border-2 border-transparent py-1.5 rounded-lg text-zinc-500 dark:text-zinc-300 outline-none w-full bg-zinc-700/10 dark:bg-zinc-700/30 px-3 focus:bg-zinc-700/20 dark:focus:bg-zinc-700/70 focus:border-zinc-200 font-mono placeholder:text-zinc-500 placeholder:text-sm placeholder:font-medium"
                :class="[
                  emailError
                    ? ' focus:border-red-500'
                    : ' focus:border-emerald-500',
                ]"
                :placeholder="$t('business_email')"
                v-model="email"
              />
              <span
                class="text-xs text-red-400 font-bold absolute top-0 right-0 lowercase"
              >
                {{ emailError }}
              </span>
            </div>
            <div class="w-full relative">
              <span
                class="text-xs font-semibold text-zinc-500 uppercase mb-2 block"
                >{{ $t("password") }}</span
              >
              <input
                type="text"
                form="editUserForm"
                class="text-xs 2xl:text-sm peer border-2 border-transparent py-1.5 rounded-lg text-zinc-500 dark:text-zinc-300 outline-none w-full bg-zinc-700/10 dark:bg-zinc-700/30 px-3 focus:bg-zinc-700/20 dark:focus:bg-zinc-700/70 focus:border-zinc-200 font-mono placeholder:text-zinc-500 placeholder:text-sm placeholder:font-medium"
                :class="[
                  passwordError
                    ? ' focus:border-red-500'
                    : ' focus:border-emerald-500',
                ]"
                :placeholder="$t('password')"
                v-model="password"
              />
              <span
                class="text-xs text-red-400 font-bold absolute top-0 right-0 lowercase"
              >
                {{ passwordError }}
              </span>
            </div>
          </div>
          <div
            class="w-full relative p-5 border border-zinc-300 dark:border-zinc-700 rounded-md overflow-y-auto max-h-[50vh] 2xl:max-h-[60vh] sidebar"
          >
            <div class="flex justify-between items-center mb-5">
              <h2 class="text-zinc-500 uppercase text-xs font-semibold">
                {{ $t("permissions") }}
              </h2>
              <label
                for="checkAll"
                class="inline-flex items-center cursor-pointer text-xs 2xl:text-sm text-zinc-600 dark:text-zinc-200"
              >
                <div class="relative mr-3">
                  <input
                    @change="toggleAllPermissions"
                    type="checkbox"
                    id="checkAll"
                    class="sr-only toggle peer"
                    :checked="isAllModulesEnabled(permissions)"
                    ref="checkAllInput"
                  />
                  <div
                    class="block bg-gray-500 peer-checked:bg-emerald-500 w-8 h-5 rounded-full"
                  ></div>
                  <div
                    class="dot absolute left-1 top-1 bg-white w-3 h-3 rounded-full transition"
                  ></div>
                </div>
                {{ $t("all") }}
              </label>
            </div>
            <div
              class="mb-10"
              v-for="(permission, index) in permissions"
              :key="index"
            >
              <label
                :for="permission.modulos_central"
                class="inline-flex items-center cursor-pointer text-xs 2xl:text-sm text-zinc-600 dark:text-zinc-200"
              >
                <div class="relative mr-3">
                  <input
                    @change="
                      toggleModulePermissions(permission.modulos_central)
                    "
                    type="checkbox"
                    form="editUserForm"
                    :name="permission.modulos_central"
                    :id="permission.modulos_central"
                    :checked="isModuleEnabled(permission.opcoes)"
                    class="sr-only toggle peer"
                  />
                  <div
                    class="block bg-gray-500 peer-checked:bg-emerald-500 w-8 h-5 rounded-full"
                  ></div>
                  <div
                    class="dot absolute left-1 top-1 bg-white w-3 h-3 rounded-full transition"
                  ></div>
                </div>
                <span>
                  {{ capitalizeString(permission.modulos_central) }}
                </span>
                <span class="text-zinc-500/80 dark:text-zinc-500 ml-2">
                  ({{ $t("check_all") }})
                </span>
              </label>
              <div class="mt-3 flex flex-wrap gap-2 sm:gap-5">
                <Checkbox
                  v-for="(submodule, index) in permission.opcoes"
                  :formRef="'editUserForm'"
                  :key="index"
                  :inputName="permission.name + '[]'"
                  :productID="submodule.name"
                  :theName="submodule.name"
                  :hasPermission="submodule.active"
                />
              </div>
              <hr class="my-5 border-zinc-300 dark:border-zinc-700" />
            </div>
          </div>
          <footer class="flex items-center justify-end gap-5 mt-5">
            <CloseButton :closeText="$t('close')" @click="aside = false" />
            <SaveButton
              form="editUserForm"
              type="submit"
              :saveText="$t('save')"
            />
          </footer>
        </div>
      </aside>
    </Transition>
  </section>

  <CreateUser
    v-if="isOpen === 'create-user-modal'"
    @handle-notification="handleNotification"
    @refresh-list="getPabxUsers"
    @close-modal="closeModal"
  />

  <DeleteUserModal
    v-if="isOpen === 'delete-user-modal'"
    :modalName="'delete-user-modal'"
    :user="userToBeDeleted"
    :closeModal="closeDeleteModal"
    @handle-delete-action="handleDeleteAction"
  />

  <Teleport to="body">
    <Transition name="notification">
      <Notification
        v-if="notificationState.show"
        :msg="notificationState.msg"
        :status="notificationState.status"
      />
    </Transition>
  </Teleport>
</template>

<script>
import { usePabxUserStore } from "../../../../store/PabxUsersStore";
import { Link } from "@inertiajs/inertia-vue3";
import Header from "../../../components/header/TheHeader.vue";
import UsersTable from "./table/UserTable.vue";
import CreateButton from "../../../components/buttons/TheCreator.vue";
import CreateUser from "./modal/CreateUser.vue";
import SaveButton from "../../../components/buttons/TheSaver.vue";
import CloseButton from "../../../components/buttons/TheCloser.vue";
import Searcher from "../../../components/inputs/TheSearcher.vue";
import Checkbox from "./buttons/CheckboxPermission.vue";
import DeleteUserModal from "../../../components/modal/Delete.vue";
import Notification from "../../../components/notification/Notification.vue";
import { useForm, useField } from "vee-validate";
import { reactive, ref } from "@vue/reactivity";
import { computed, onMounted } from "@vue/runtime-core";
import { onClickOutside } from "@vueuse/core";
import {
  ArrowSmLeftIcon,
  UserIcon,
  AtSymbolIcon,
  LockClosedIcon,
  EyeIcon,
  EyeOffIcon,
  XIcon,
} from "@heroicons/vue/outline";
import i18n from "../../../../i18n";

export default {
  components: {
    Link,
    Header,
    UsersTable,
    CreateButton,
    CreateUser,
    SaveButton,
    CloseButton,
    Searcher,
    Checkbox,
    DeleteUserModal,
    Notification,
    ArrowSmLeftIcon,
    UserIcon,
    AtSymbolIcon,
    LockClosedIcon,
    EyeIcon,
    EyeOffIcon,
    XIcon,
  },
  setup(_, { emit }) {
    const PabxUserStore = usePabxUserStore();
    const pabxUsers = computed(() => PabxUserStore.users);

    const userInfos = ref({});

    let permissions = ref([]);
    const userForm = ref(null);
    const checkAllInput = ref(null);

    let isOpen = ref(false);
    let aside = ref(false);
    const editMenuAside = ref(null);

    let notificationState = reactive({
      msg: "",
      status: "",
      show: false,
    });

    onClickOutside(editMenuAside, () => (aside.value = null));

    function isModuleEnabled(options) {
      for (const item of options) {
        if (!item.active) {
          console.log(item);
          return false
        }
      }

      return true;
    }

    function isAllModulesEnabled(permissions) {
      for (const permission of permissions) {
        for (const item of permission.opcoes) {
          if (!item.active) {
            console.log(item);
            return false
          }
        }
      }

      return true;
    }

    function capitalizeString(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function closeModal() {
      isOpen.value = false;
    }

    function getModules() {
      PabxUserStore.getModules()
        .then(({ data }) => {
          permissions.value = data.data;
        })
        .catch((error) => console.log(error));
    }

    function handleNotification(notificationInfos) {
      const { msg, status, show } = notificationInfos;

      notificationState.msg = msg;
      notificationState.status = status;
      notificationState.show = show;

      setTimeout(() => {
        notificationState.msg = "";
        notificationState.status = "";
        notificationState.show = false;
      }, 2500);
    }

    function getPabxUsers() {
      const pabxId = route().params.id;
      PabxUserStore.getPabxUsers(pabxId)
        .then(({ data }) => {
          PabxUserStore.users = data.data;

          if (pabxUsers.length < 1) aside.value = false;
        })
        .catch((error) => console.log(error));
    }

    function validateEmail(mail) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return true;
      }
      return false;
    }

    function toggleAllPermissions() {
      let inputs = Array.from(userForm.value.elements);

      inputs.forEach((input) => {
        if (input.type === "checkbox") {
          input.checked = checkAllInput.value.checked;
        }
      });
    }

    function toggleModulePermissions(module) {
      let inputs = userForm.value.elements;

      let currentModule = null;
      let currentPermissions = null;

      if (module === "sip") {
        currentModule = inputs["sip"];
        currentPermissions = inputs["modulos_sip[]"];
      }

      if (module === "dialplan") {
        currentModule = inputs["dialplan"];
        currentPermissions = inputs["modulos_dialplan[]"];
      }

      if (module === "pabx") {
        currentModule = inputs["pabx"];
        currentPermissions = inputs["modulos_pabx[]"];
      }

      if (module === "gerenciamento") {
        currentModule = inputs["gerenciamento"];
        currentPermissions = inputs["modulos_gerenciamento[]"];
      }

      if (module === "relatorios_persa") {
        currentModule = inputs["relatorios_persa"];
        currentPermissions = inputs["modulos_relatorios_persa[]"];
      }

      if (module === "callcenter") {
        currentModule = inputs["callcenter"];
        currentPermissions = inputs["modulos_callcenter[]"];
      }

      if (Array.from(currentPermissions).length > 0) {
        Array.from(currentPermissions).forEach((perm) => {
          perm.checked = currentModule.checked;
        });
      } else {
        currentPermissions.checked = currentModule.checked;
      }
    }

    const userSchema = {
      name(value) {
        if (!value) {
          return "campo obrigatório";
        }
        if (value.length < 3) {
          return "minímo 3 caracteres";
        }

        return true;
      },
      email(value) {
        if (!value) {
          return "campo obrigatório";
        }
        if (!validateEmail(value)) {
          return "email inválido";
        }

        return true;
      },
      password(value) {
        if (value && value.length < 3) {
          return "minímo 3 caracteres";
        }

        return true;
      },
    };

    // Create a form context with the validation schema
    const { handleSubmit } = useForm({
      validationSchema: userSchema,
    });

    // No need to define rules for fields
    const { value: name, errorMessage: nameError } = useField("name");
    const { value: email, errorMessage: emailError } = useField("email");
    const { value: password, errorMessage: passwordError } =
      useField("password");

    function onInvalidSubmit() {
      const notificationInfos = {
        msg: i18n.global.t('check_fields_and_try_again'),
        status: "error",
        show: true,
      };

      emit("handleNotification", notificationInfos);
    }

    const editUserSetup = handleSubmit((values) => {
      let inputs = userForm.value.elements;
      let modulos_sip = inputs["modulos_sip[]"];
      let modulos_dialplan = inputs["modulos_dialplan[]"];
      let modulos_pabx = inputs["modulos_pabx[]"];
      let modulos_gerenciamento = inputs["modulos_gerenciamento[]"];
      let modulos_relatorios_persa = inputs["modulos_relatorios_persa[]"];
      let modulos_callcenter = inputs["modulos_callcenter[]"];

      modulos_sip = Array.from(modulos_sip)
        .map((item) => {
          if (item.checked) return item.id;
        })
        .filter((item) => item);

      modulos_dialplan = Array.from(modulos_dialplan)
        .map((item) => {
          if (item.checked) return item.id;
        })
        .filter((item) => item);

      modulos_pabx = Array.from(modulos_pabx)
        .map((item) => {
          if (item.checked) return item.id;
        })
        .filter((item) => item);

      modulos_gerenciamento = Array.from(modulos_gerenciamento)
        .map((item) => {
          if (item.checked) return item.id;
        })
        .filter((item) => item);

      modulos_relatorios_persa = Array.from(modulos_relatorios_persa)
        .map((item) => {
          if (item.checked) return item.id;
        })
        .filter((item) => item);

      //Special case: has only one item
      /*modulos_callcenter = Array.from(modulos_callcenter)*/
        /*.map((item) => {*/
          /*if (item.checked) return item.id;*/
        /*})*/
        /*.filter((item) => item);*/

      if (modulos_callcenter.checked) {
        modulos_callcenter = ['gerenciamento_gestor'];
      } else {
        modulos_callcenter = [];
      }

      //login
      userInfos.value.nome = values.name;
      userInfos.value.login = values.email;
      userInfos.value.senha = values.password;

      //permissions
      userInfos.value.modulos_sip = modulos_sip;
      userInfos.value.modulos_dialplan = modulos_dialplan;
      userInfos.value.modulos_pabx = modulos_pabx;
      userInfos.value.modulos_gerenciamento = modulos_gerenciamento;
      userInfos.value.modulos_relatorios_persa = modulos_relatorios_persa;
      userInfos.value.modulos_callcenter = modulos_callcenter;

      const server = route().params.server;
      userInfos.value.id_empresas = route().params.id;

      PabxUserStore.editUser(server, userInfos.value)
        .then(({ data }) => {
          const notificationInfos = {
            msg: data.message,
            status: "success",
            show: true,
          };
          handleNotification(notificationInfos);
          getPabxUsers();
          aside.value = false;
        })
        .catch((error) => {
          const notificationInfos = {
            msg: error,
            status: "error",
            show: false,
          };

          emit("handleNotification", notificationInfos);
        });
    }, onInvalidSubmit);

    onMounted(() => {
      getPabxUsers();
      getModules();
    });

    return {
      userInfos,
      pabxUsers,
      permissions,
      capitalizeString,
      editUserSetup,
      userSchema,
      userForm,
      name,
      nameError,
      email,
      emailError,
      password,
      passwordError,
      toggleAllPermissions,
      toggleModulePermissions,
      checkAllInput,
      isOpen,
      closeModal,
      notificationState,
      aside,
      handleNotification,
      getPabxUsers,
      editMenuAside,
      isModuleEnabled,
      isAllModulesEnabled,
    };
  },
  data() {
    return {
      PabxUserStore: usePabxUserStore(),
      pabxType: route().params.type,
      userInfos: {},
      login: "",
      passwordType: "password",
      userToBeDeleted: {},
    };
  },
  methods: {
    togglePasswordVisibility() {
      if (this.passwordType === "password") this.passwordType = "text";
      else this.passwordType = "password";
    },
    closeDeleteModal() {
      this.isOpen = false;
    },
    createUserModal() {
      this.aside = false;
      this.isOpen = "create-user-modal";
    },
    deleteUserModal(user) {
      this.userToBeDeleted = user;
      this.isOpen = "delete-user-modal";
    },
    handleDeleteAction(userId) {
      this.deleteUser(userId);
    },
    getUser(userId) {
      const server = route().params.server;
      this.userId = userId;
      this.PabxUserStore.getUser(server, userId)
        .then(({ data }) => {
          this.userInfos = data.data;
          this.name = this.userInfos.nome;
          this.email = this.userInfos.login;

          this.login = this.userInfos.login;
          this.permissions = data.data.permissoes;
          this.aside = true;
        })
        .catch((error) => console.log(error));
    },
    deleteUser(userId) {
      const server = route().params.server;
      this.PabxUserStore.deleteUser(server, userId)
        .then(({ data }) => {
          this.getPabxUsers();
          this.aside = false;
          this.isOpen = false;

          const notificationInfos = {
            msg: data.message,
            status: "success",
            show: true,
          };

          this.handleNotification(notificationInfos);
        })
        .catch((error) => {
          const notificationInfos = {
            msg: error,
            status: "error",
            show: error,
          };

          this.handleNotification(notificationInfos);
        });
    },
  },
};
</script>
<style>
/* Toggle */
.toggle:checked ~ .dot {
  transform: translateX(100%);
}

.aside-enter-active,
.aside-leave-active {
  transition: all 300s ease;
  right: 0;
}

.aside-enter-from,
.aside-leave-to {
  opacity: 0;
  right: -500px;
}
</style>
